<template>
  <SwitchGroup ref="toggleRef">
    <SwitchLabel
      as="div"
      class="group p-3 space-x-2 flex text-sm text-gray-500 font-medium border border-gray-300 shadow-sm rounded focus-within:shadow-blue-100 focus:outline-none focus-within:border-blue-300 focus-within:ring-1 focus-within:ring-blue-200 focus-within:ring-offset-1 focus-within:ring-offset-blue-200"
      :class="[
        isDisabled ? 'cursor-not-allowed bg-gray-50' : 'cursor-pointer  hover:border-gray-400',
        { 'items-center': !isMultiLine },
      ]"
    >
      <Switch
        :modelValue="modelValue"
        @update:modelValue="value => emit('update:modelValue', value)"
        :class="{
          'bg-primary group-hover:bg-primary-hover group-focus-within:bg-primary-hover':
            modelValue && !isDisabled,
          'bg-gray-300 group-hover:bg-gray-400 group-focus-within:bg-gray-400':
            !modelValue && !isDisabled,
          'bg-gray-300': modelValue && isDisabled,
          'bg-gray-200': !modelValue && isDisabled,
        }"
        :disabled="isDisabled"
        class="relative pointer-events-none inline-flex h-4 w-8 shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none"
      >
        <span
          aria-hidden="true"
          :class="modelValue ? 'translate-x-4' : 'translate-x-0'"
          class="pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white transition duration-200 ease-in-out"
        />
      </Switch>
      <div class="w-full">
        <div
          class="flex justify-between space-x-2"
          :class="isMultiLine ? 'items-start' : 'items-center'"
        >
          <InputLabel v-if="hasLabel" :id="id || name" class="pointer-events-none">
            <slot name="label">
              {{ label }}
            </slot>
          </InputLabel>
          <LabelBlocked
            v-if="blocked"
            :blocked-data="blockedData"
            @click="emit('blockedLabelClick')"
          />
        </div>
        <p v-if="hasDescription" class="block mt-1 font-normal">
          <slot name="description">
            {{ description }}
          </slot>
        </p>
        <slot></slot>
      </div>
    </SwitchLabel>
  </SwitchGroup>
</template>

<script setup>
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue"
import InputLabel from "../inputs/_base/InputLabel.vue"
import LabelBlocked from "@/components/elements/labels/LabelBlocked.vue"
import { ref, useSlots, computed, onMounted } from "vue"

const props = defineProps({
  id: String,
  name: String,
  label: String,
  description: String,
  modelValue: Boolean,
  disabled: Boolean,
  blocked: Boolean,
  blockedData: Object,
})
const emit = defineEmits(["update:modelValue", "blockedLabelClick"])

const slots = useSlots()
const hasLabel = computed(() => props.label || slots["label"])
const hasDescription = computed(() => props.description || slots["description"])

const isDisabled = computed(() => props.disabled || props.blocked)

const toggleRef = ref(null)
const isMultiLine = ref(false) // to center align toggle with text
onMounted(() => {
  if (toggleRef.value.$el.offsetHeight > 50) {
    isMultiLine.value = true
  }
})
</script>
